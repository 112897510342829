import React, { useState, useEffect, FC, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import "../assets/style/DeliveryTypes.css"
import { useLogout, useTranslate, useJSONState } from '../hooks';
import { TextField, Grid, Container, Group, Centralizer, Select, ChangeDialog, ConfirmDialog, Button } from '../components';
import { logger, deepCopyOf, hasCommonValue, areIdentical } from '../utils';
import { FaCircleXmark, FaRegCircleXmark, FaPlus } from 'react-icons/fa6';


/*
NSN 
1. Går just ny inte Ändra deliveryType då den uppdaterar sig själv och tappar fokus direkt.
2. Save ska göra API-anrop
3. Delete ska göra API-anrop, eller?
*/

export default function DeliveryTypes() {
  const api = useAxiosPrivate();
  const [changed, setChanged] = useState(false) //Checks if form has changed
  const [showDeleteDT, setShowDeleteDT] = useState(-1);
  const { t } = useTranslate();
  const submitForm = useRef<HTMLFormElement>(null);
  const navigate = useNavigate()
  const [ signals, setSignals ] = useState()

  const [carriers, setCarriers] = useState<carrierDataType[]>([]);
  const [carriersuites, setCarriersuites] = useState<carriersuiteDataType>({});
  const [dialog, setDialog] = useState<any>(null);
  const [deliveryTypes, setDtProp, setDeliveryTypes] = useJSONState<dtType[]>([]);
  const [originalDeliveryTypes, setOriginalDeliveryTypes] = useState<dtType[]>([]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getDeliveryTypes = async (initial: boolean) => {
      const response = await api.get({endpoint: "/deliveryTypes", mount:null})
      try {
        console.log("the response:", response)

        const carrierData = response.data?.carrierData ? deepCopyOf(response.data?.carrierData) : []
        const carriersuiteData = response.data?.carriersuiteData ? deepCopyOf(response.data?.carriersuiteData) : []
        const deliveryTypesData = response.data?.deliveryTypes ? deepCopyOf(response.data?.deliveryTypes) : []

        if (isMounted) {
          if (carrierData.length > 0)
            setCarriers(carrierData)
          if (Object.keys(carriersuiteData).length > 0)
            setCarriersuites(carriersuiteData)
          if (Object.keys(deliveryTypesData).length > 0) {
            setDeliveryTypes(deliveryTypesData)
            setOriginalDeliveryTypes(deliveryTypesData)
          }
        }
        else {
          if (initial) return
        }
      } catch (e) {
        console.error(e)
        //logout()
      }
    }

    getDeliveryTypes(true)

    return () => {
      isMounted = false;
      controller.abort()
    }
  }, [])

  useEffect(() => {
    if(areIdentical(deliveryTypes, originalDeliveryTypes)) return 
    setChanged(true)
  }, [deliveryTypes, originalDeliveryTypes])

  const addDeliveryType = () => {
    let current = deepCopyOf(deliveryTypes) as any
    current[current.length] = {
      deliverType: "",
      description: "",
      carrierId: "",
      dom: {
        value: undefined,
        serviceCodes: []
      },
      eu: {
        value: undefined,
        serviceCodes: []
      },
      ex: {
        value: undefined,
        serviceCodes: []
      }
    }
    setDeliveryTypes(current)
  }

  const updateServiceCode = (tradeArea: tradeAreaType, index: number, value: (string | number)) => {
    if(deliveryTypes === undefined) return
    let len = deliveryTypes[index][tradeArea]?.serviceCodes?.length
    if(len === undefined) return
    if (typeof value === "string") {
      setDtProp(value, index, tradeArea, "serviceCodes", len)
    } else {
      setDtProp(null, index, tradeArea, "serviceCodes", value, -1)
    }
  }

  const getCarrierSuiteId = (carrierId: string) => {
    const i = carriers.findIndex((c: carrierDataType) => c.value === carrierId)
    if (i === -1) return ""
    return carriers[i].carrierSuiteId;
  }

  const getServiceName = (carrierSuiteId: string, tradeArea: tradeAreaType, productCode: string, serviceCode: string) => {

    if (!carriersuites[carrierSuiteId] || !carriersuites[carrierSuiteId][tradeArea])
      return "--"

    const productTypes = carriersuites[carrierSuiteId][tradeArea] as productType[]
    let i = productTypes.findIndex((p: productType) => p.value === productCode) || -1

    if (i === -1 || (i >= 0 && productTypes[i].serviceCodes?.length === 0))
      return "--"

    const serviceCodes = productTypes[i].serviceCodes as serviceCode[]
    const j = serviceCodes.findIndex((s: serviceCode) => s.value === serviceCode)
    if (j === -1)
      return "--"

    return serviceCodes[j].name;
  }

  const deleteDeliveryType = (i: number) => {
    setDialog({
      title: t("dialogs.sureDeleteDT"),
      type: "delete",
      onCancel: () => setDialog(null),
      onOk: () => {
        setDtProp(null, i, -1)
        setDialog(null)
      },
      message: t("dialogs.deleteInfo"),
      okText: t("delete")
    })
  }

  const changeCarrier = (carrierId: string, i: number) => {
    const dt = deepCopyOf(deliveryTypes) as any

    dt[i].carrierId = carrierId
    dt[i].dom = {
      name: undefined,
      value: undefined,
      serviceCodes: []
    }
    dt[i].eu = {
      name: undefined,
      value: undefined,
      serviceCodes: []
    }
    dt[i].ex = {
      name: undefined,
      value: undefined,
      serviceCodes: []
    }
    setDeliveryTypes(dt);
  }

  const onSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    //Check if we have duplicate deliverytypes
    let duplicateRows: Set<string> | Array<string> = deliveryTypes.reduce((res: Set<string>, item: dtType, index: number) => {
      const deliveryType: string = item.deliveryType.toLowerCase();
      if (deliveryTypes.findIndex((d: dtType, i: number) => i !== index && d.deliveryType.toLowerCase() === deliveryType) !== -1)
        res.add(item.deliveryType)
      return res
    }, new Set());

    duplicateRows = Array.from(duplicateRows);

    if (duplicateRows.length > 0)
      return setDialog({
        title: t("errorTexts.unableToSave"),
        type: "warning",
        noCancel: true,
        onOk: () => setDialog(null),
        message: t("dialogs.duplicateDT") + duplicateRows.join(", "),
        okText: "Ok"
      })      
    
    api.put({
      endpoint: `/deliveryTypes`,
      data: deliveryTypes,
      mount: {
        loadingText: t("saving"),
        onLoadText: t("saved"),
      }
    })
      .then(() => {
        setOriginalDeliveryTypes(deliveryTypes)
      })
      .catch((error) => {
        setDialog({
          title: t("errorTexts.unexpectedErrorTryAgain"),
          type: "delete",
          noCancel: true,
          onOk: () => setDialog(null),
          okText: "Ok"
        })
      })
  }

  const AddServiceCode: FC<AddServiceCodeProps> = ({ tradeArea, index, carriersuite, productCode }) => {
    try {
      //console.log(carriersuite, productCode)
      if (!productCode) return <></>
      let dropDownData = deepCopyOf(carriersuites[carriersuite][tradeArea]) as any
      dropDownData = dropDownData.find((p: productType) => p.value === productCode).serviceCodes;

      if (!dropDownData || dropDownData.length === 0) return <></>

      const currentSCs = deliveryTypes[index][tradeArea]?.serviceCodes ?? []

      dropDownData = dropDownData.filter((sc: serviceCode) => {
        if (currentSCs.includes(sc.value)) return false // Remove already added service codes from the list to remove duplicates.
        if (sc.noCombined && hasCommonValue(sc.noCombined, currentSCs)) return false
        return true
      })

      return <select className='addServiceCode' value="" onChange={(v) => updateServiceCode(tradeArea, index, v.currentTarget.value)}>
        <option key={-1} disabled value="" className='placeholder'>+ {t("addService")}</option>
        {dropDownData.map((opt: serviceCode, ii: number) => {
          return <option key={ii} value={opt.value}>{opt.name}</option>
        })
        }
      </select>
    } catch (e) {
      console.error(e)
      return <i>Error fetchting serviceCodes</i>
    }
  }

  return (
    <div className='schipt-deliverytypes'>
      <ConfirmDialog options={dialog} />

      <Centralizer>
        <Container style={{
          position: "static",
          width: "clamp(1000px, 80%, 80vw)"
        }}  >
          <form onSubmit={(e) => onSave(e)} ref={submitForm}>
            <Group >
              <Centralizer>
                <Container style={{ maxHeight: "85vh", overflowY: "auto" }}>
                  <div className="schipt-input-table">
                    <table>
                      <thead style={{ position: "sticky", top: 0 }}>
                        <tr key={"h"} style={{ position: "sticky", top: 0 }}>
                          <th key={`dth`}>{t("deliveryType")}</th>
                          <th key={`nameh`}>{t("description")}</th>
                          <th key={`carrierh`}>{t("carrier")}</th>
                          <th key={`domh`}>{t("domestic")}</th>
                          <th key={`euh`}>{t("europe")}</th>
                          <th key={`exh`}>{t("export")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {deliveryTypes.map((deliveryType: dtType, i: number) => {
                          let carrierSuiteId = getCarrierSuiteId(deliveryType.carrierId);

                          return (
                            <tr key={i}>
                              <td key={"deliveryType"}>
                                <div className='schipt-text-field flex'>
                                  <p style={{ paddingTop: "0.3rem", paddingRight: "0.5rem", cursor: "pointer" }} onClick={() => deleteDeliveryType(i)}><FaRegCircleXmark className='text-red-600 dark:text-red-400' /></p>
                                  <TextField
                                    required={true}
                                    value={deliveryType.deliveryType}
                                    onChange={(v) => setDtProp(v.target.value, i, "deliveryType")}
                                    style={{ width: "100%" }}
                                  />
                                </div>
                              </td>
                              <td key={`description`}>
                                <div className='schipt-text-field'>
                                  <TextField
                                    required={true}
                                    value={deliveryType.description}
                                    onChange={(v) => setDtProp(v.target.value, i, "description")}
                                  />
                                </div>
                              </td>
                              <td key={`carrierId`}>
                                <div className='schipt-text-field'>
                                  <Select
                                    data={carriers}
                                    onChange={(v) => changeCarrier(v.currentTarget.value, i)}
                                    required={true}
                                    value={deliveryType.carrierId}
                                    blankOption={true}
                                  />
                                </div>
                              </td>
                              {deliveryType.carrierId && (["dom", "eu", "ex"] as tradeAreaType[]).map(((tradeArea: tradeAreaType) => {
                                if ((carriersuites[carrierSuiteId][tradeArea] ?? []).length === 0)
                                  return <td key={tradeArea} />

                                return (
                                  <td key={`${tradeArea}`}>
                                    <Select data={carriersuites[carrierSuiteId][tradeArea] ?? []}
                                      value={deliveryType[tradeArea]?.value ?? ""}
                                      onChange={(v) => setDtProp(v.currentTarget.value, i, tradeArea, "value")}
                                      blankOption={true}
                                      required={false}
                                    />
                                    {(deliveryType[tradeArea]?.serviceCodes?.length || 0) > 0 &&
                                      <div className='serviceCodes'>
                                        {
                                          deliveryType[tradeArea]?.serviceCodes?.map((serviceCode: string, sci: number) => {
                                            return <div key={sci}>
                                              <p><i
                                                onClick={() => updateServiceCode(tradeArea as tradeAreaType, i, sci)}
                                                title={t("removeService")}>-</i>
                                                {getServiceName(carrierSuiteId, tradeArea, (deliveryType[tradeArea]?.value ?? ""), serviceCode)}
                                              </p>
                                            </div>
                                          })
                                        }
                                      </div>
                                    }
                                    <AddServiceCode tradeArea={tradeArea as tradeAreaType} index={i} productCode={deliveryType[tradeArea]?.value} carriersuite={carrierSuiteId} />
                                  </td>
                                )
                              }))
                              }
                            </tr>)
                        })
                        }
                        <tr>
                          <td>
                            <p style={{ padding: "0.8rem", cursor: "pointer", color: "lime", fontSize: "1.5rem", fontWeight: 900 }}
                              title={t("addDeliveryType")}
                              onClick={() => { addDeliveryType() }}
                            ><FaPlus className='text-schipt-green dark:text-schipt-green' /></p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Container>
              </Centralizer>
            </Group>
            <ChangeDialog show={changed}
              undo={async () => {
                setDeliveryTypes(originalDeliveryTypes)
              }}
            />
          </form>
        </Container>
      </Centralizer>
    </div>
  );
}