import React, { FC, useState, useEffect, useRef } from "react";
import { FaCircleExclamation, FaChevronUp, FaChevronDown } from "react-icons/fa6";

type Props = {
    children?: JSX.Element | JSX.Element[] | string,
    style?: any,
    [key: string]: any
};

const ErrorMessage: FC<Props> = ({ children, style, ...props }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [shouldShowChevron, setShouldShowChevron] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const updateShouldShowChevron = () => {
        if (contentRef.current) {
            const contentHeight = contentRef.current.scrollHeight;
            const maxHeight = 20; // max-h-20 in pixels
            const screenWidth = window.innerWidth;
            setShouldShowChevron(contentHeight > maxHeight || screenWidth < 575);
        }
    };

    const determineErrorColor = () => {
        const textContent =
            typeof children === "string"
            ? children
            : React.Children.map(children, (child) => {
                if (child && typeof child === "object" && "props" in child){
                    return child.props.children;
                }
                return typeof child === "string" ? child : "";
            })?.join(" ");

            if (textContent){
                const firstSentence = textContent.split(/[.!?]/)[0];
                if (firstSentence.toLowerCase().includes("warning")){
                    return "bg-orange-200 text-orange-600";
                }

            }
            return "bg-red-200 text-red-600"
    }

    useEffect(() => {
        updateShouldShowChevron();
        
        const handleResize = () => {
            updateShouldShowChevron();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [children, isExpanded]);

    return (
        <>
            <div
                {...props}
                onClick={toggleExpand}
                className={`schipt-grid flex row relative font-montserrat w-auto font-medium text-sm rounded-md p-2 mx-4 mb-0 
                ${determineErrorColor()}  
                ${isExpanded ? 'max-h-screen' : 'max-h-9'} 
                overflow-hidden transition-max-height duration-300 ease-in-out 
                ${props.className || ''}`}
                style={style}
            >
                <div className="flex items-center">
                    <FaCircleExclamation className='text-red-600 text-xl mr-4 absolute top-2' />
                    <div className="flex-grow px-12" ref={contentRef}>
                        {children}
                    </div>
                    {shouldShowChevron && (
                        <div className="absolute top-2 right-2">
                            {isExpanded ? <FaChevronUp className='text-red-600 text-xl' /> : <FaChevronDown className='text-red-600 text-xl' />}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ErrorMessage;
