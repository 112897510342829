import { axiosPrivate } from "../api/axios";
import { AxiosResponse } from "axios"
import { useEffect, useRef } from "react";
import { toast } from "sonner";
import { useAuth , useRefreshToken, useTranslate } from "../hooks"
import { sleep } from "../utils";

type MountType = {
  loadingText?: string;
  onLoadText?: string | {text:string, type: "success" | "info"};
  skeleton?: boolean | number
}

const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  const { auth } = useAuth();
  const { language } = useTranslate()
  const abortControllersRef = useRef(new Set());

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      config => {
        config.headers = config.headers ?? {};
        if (!config.headers?.["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${auth?.accessToken}`;
        }
        config.headers["Accept-Language"] = language || "en";
        return config;
      },
      (error) => Promise.reject(error)
    )
    const responseIntercept = axiosPrivate.interceptors.response.use(
      response => response,
      async (error) => {

        const prevRequest = error?.config;
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          if(!newAccessToken) console.log("newAccessToken IS FUCKING NULL???")
          prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return axiosPrivate(prevRequest);
        }
        return Promise.reject(error);
      }
    )

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    }
  }, [auth, refresh, language])

  const __fetcher = async (method: "GET" | "POST" | "PUT" | "DELETE", 
    config: {
      endpoint: string,
      data?: {[key:string]: any},
      params?: {[key:string]: any},
      mount: MountType | null
    }
  )  => {
    let toastId: number | string | undefined;
    let mount = config.mount

    let isMounted = true
    let skeletor: DOMTokenList | undefined;

    try{        
      if(mount !== null){
        if (mount.loadingText) toastId = toast.loading(mount.loadingText)
        if(mount.skeleton){
          skeletor = document.getElementById("skeletor")?.classList;

          if(typeof mount.skeleton === "number"){
            skeletor?.add("skeleton-mount")
            await sleep(mount.skeleton)
          }else setTimeout(() => {isMounted && skeletor?.add("skeleton-mount")}, 300)
        }
      }

      //await sleep(2000);

      let response;
      if(method === "GET") response = await axiosPrivate.get(config.endpoint, config.params)
      else if(method === "POST") response = await axiosPrivate.post(config.endpoint, config.data)
      else if(method === "PUT") response = await axiosPrivate.put(config.endpoint, config.data)
      else if(method === "DELETE") response = await axiosPrivate.delete(config.endpoint, config.params)

      isMounted = false;

      if(mount){
        if(toastId !== undefined){
          if(mount.onLoadText){
            if(typeof mount.onLoadText === "string") toast.success(mount.onLoadText, { id: toastId });
            else {
              const { text, type } = mount.onLoadText
              toast[type](text, { id : toastId})
            }
          }
          else toast.dismiss(toastId)
        }
        if(mount.skeleton && skeletor) {skeletor.contains("skeleton-mount") && skeletor.remove("skeleton-mount")}
      }

      return Promise.resolve(response as AxiosResponse<any, any>)
    }catch(e){
      isMounted = false
      if(skeletor) {skeletor.contains("skeleton-mount") && skeletor.remove("skeleton-mount")}
      console.log({waaH:true, e})
      let { status } = e.response
      if(status < 500){
        let errMsg = e.response?.data?.message || e.response?.data || e.message
        if(toastId) toast.error(errMsg, { id: toastId });
        else toast.error(errMsg)
      }
      else if(toastId !== undefined) toast.dismiss(toastId)
     
      return Promise.reject(e as AxiosResponse<any, any>)
    }    
  }

  const ret = {
    get: async function(config: {
      endpoint: string,
      params?: {[key:string]: string | number | boolean}
      mount: MountType | null 
    }){return __fetcher("GET", config)},

    post: async function(config: {
      endpoint: string,
      data?: {[key:string]: any} | {[key:string]: any}[]
      mount: MountType | null   
    }){return __fetcher("POST", config)},

    put: async function(config: {
      endpoint: string,
      data?: {[key:string]: any} | {[key:string]: any}[]
      mount: MountType | null    
    }){return __fetcher("PUT", config)},

    delete: async function(config: {
      endpoint: string,
      params?: {[key:string]: string | number | boolean}
      mount: MountType | null   
    }){return __fetcher("DELETE", config)},
  }


  return ret;
}

export default useAxiosPrivate