import React, { FC, useMemo, useState } from 'react'
import "../../assets/style/TextField.css"
import { v4 as uuidv4 } from 'uuid';
import plugin from 'tailwindcss';

type InputType = 'text' | 'email' | 'search' | 'password' | 'date' | 'number' | 'month' | 'week' | 'time' | 'file' | 'tel' | "textArea"

type Props<T extends InputType>/*<T extends 'text' | 'email' | 'search' | 'password' | 'date' | 'number' | 'month' | 'week' | 'time' | 'file' | 'tel'>*/ = {
  children?: string,
  _type?:  T,
  _ref?: React.MutableRefObject<null>,
  adornment?: string,
  required?: boolean,
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
  autoFocus?: boolean,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  value?: undefined | null | (T extends "number" ? number : string);
  readOnly?: boolean,
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void,
  title?: string,
  style?: any,
  error?: string,
  className?: string,
  decimals?: T extends "number" ? number : undefined,
  max?: T extends "number" ? number : undefined;
  min?: T extends "number" ? number : undefined;
  [key: string]: any
}

/**
 * 
 * @param children text to be displayed
 * @param _type text | email | search | password | date | number | month | week | time | file | tel
 * @param {string} _ref a ReaMutableRefObject
 * @param {string} adornment unit to be displayed. Examples: kg, km
 * @param {boolean} required 
 * @param {function} onKeyDown 
 * @param {boolean} autoFocus 
 * @param {function} onChange 
 * @param {string} value 
 * @param {string} readOnly 
 * @param {string} onBlur
 * @param {string} title hovertext
 * @returns 
 */
const TextField = <T extends InputType>({ children, _type, _ref, adornment, required, onKeyDown, autoFocus
  , onChange, value, readOnly, onBlur, title, error, className, decimals, min, max, ...props }:Props<T>) => {
  const id = useMemo(() => uuidv4(), [])

  const [ isFocused, setIsFocused ] = useState(false)

  if(_type === "number" && value){
    let decs = ""
    let sValue = value != undefined ? value?.toString() : "";
    // we work with whole numbers. replace all dots
    if (!decimals || decimals < 1) {
      sValue = sValue.toString().replace(/[^0-9]/g, '');
    } else {
      sValue = sValue.toString().replaceAll(",", '.');
      sValue = sValue.replace(/[^0-9.]/g, '');
      let firstDotApp = sValue.indexOf(".")
      // Remove trailing dots. only 1 dot allowed.
      if (firstDotApp > 0) {
        let wholeNumAndDot = sValue.substring(0, firstDotApp + 1)
        decs = sValue.substring(firstDotApp + 1).replaceAll(".", "")

        sValue = wholeNumAndDot + decs;
      } else if (firstDotApp === 0) { // if dot is the first letter we add a 0 before it
        sValue = "0" + sValue
        firstDotApp = 1
      }

      if (isNaN(parseFloat(sValue))) sValue = ""
      else {
        // dot is the last character, do not remove..
        if (firstDotApp < sValue.length - 1)
          sValue = parseFloat(parseFloat(sValue).toFixed(Math.min(decimals, decs.length))).toString();
      }
    }
    if (max && sValue && parseFloat(sValue.toString()) > max) sValue = max.toString()
    
    value = sValue as (T extends "number" ? number : string)
  }

  if(isFocused)
    return <div className='relative w-full md:w-auto schipt-i'>
      <textarea 
        required={required}
        readOnly={readOnly}
        value={value ?? ""}
        onKeyDown={(e) => onKeyDown?.((e as unknown) as React.KeyboardEvent<HTMLInputElement>)}
        onChange={(e) => onChange?.((e as unknown) as React.ChangeEvent<HTMLInputElement>)}
        ref={_ref}
        placeholder={children}
        onFocus={(e) => {
          const el = e.target as HTMLTextAreaElement;
          // Set the cursor to the end of the value
          el.selectionStart = el.value.length;
          el.selectionEnd = el.value.length;
        }}
        onBlur={(e) => {
          onBlur?.((e as unknown) as React.FocusEvent<HTMLInputElement, Element>)
          setIsFocused(false)
        }}
        autoFocus={true}
        title={title}
        {...props}
        className={`w-full w-80 h-32 py-1 pl-1 border-schipt-dark-gray read-only:opacity-50 read-only:pointer-events-none border-solid border text-sm font-montserrat rounded-sm bg-white dark:bg-schipt-dark focus:outline-none placeholder-transparent  focus:shadow-3xl focus:shadow-schipt-dark-gray transition-colors peer ` +
          (className ?? "")
        }
      />
    </div>    

  return (<>
    <div className='relative w-full md:w-auto schipt-i'>
      <input 
        type={["number","textArea"].includes(_type??"") ? "text" : (_type ?? "text")}
        inputMode={_type === "number" && decimals && decimals > 0 ? "decimal" :
          _type === "number" ? "numeric" : undefined
        }
        id={id}
        placeholder={children}
        ref={_ref}
        required={required ?? false}
        autoFocus={autoFocus ?? false}
        onKeyDown={(e) => onKeyDown?.(e)}
        onChange={(e) => onChange?.(e)}
        value={(_type === "textArea" && typeof value === "string" && value?.length > 20) ? value.substring(0, 20) + "..." : (value || '')}
        readOnly={readOnly}
        onFocus={() => _type === "textArea" && setIsFocused(true)}
        onBlur={(e) => _type !== "textArea" && onBlur?.(e)}
        title={title}
        {...props}
        className={`w-full py-1 pl-1 border-schipt-dark-gray read-only:opacity-50 read-only:pointer-events-none border-solid border text-sm font-montserrat rounded-sm bg-white dark:bg-schipt-dark focus:outline-none placeholder-transparent  focus:shadow-3xl focus:shadow-schipt-dark-gray transition-colors peer ` +
          (_type === "email" ? "w-80 " : " ") +
          (className ?? "")
        }
      />
      <label htmlFor={id} className="absolute left-0 -top-4  text-schipt-dark-gray dark:text-schipt-white-90 text-xs font-semibold font-montserrat peer-focus:-top-4 peer-focus:font-md peer-focus:text-md peer-focus:left-0 peer-focus:pl-0 transition-all peer-placeholder-shown:text-schipt-dark-gray dark:peer-placeholder-shown:text-schipt-white-60 peer-placeholder-shown:text-opacity-50 peer-placeholder-shown:pl-1 peer-placeholder-shown:top-2 pointer-events-none">
        {children}
      </label>
      {adornment &&
        <p className='adornment'>{adornment}</p>
      }
      {error ? <><br /><p className='absolute -top-5 -right-0 text-xs text-rose-500 w-100 text-nowrap'>{error}</p></> : <></>}
    </div>
  </>
  )
}

export default TextField;