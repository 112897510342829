import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import "../assets/style/Carriers.css"
import { useLogout, useTranslate, useJSONState } from '../hooks';
import { TextField, Grid, DataTable
  , Container, Group, Accordion, Centralizer, Button, Select, Switch, ChangeDialog, ConfirmDialog} from '../components';
import { logger, deepCopyOf, areIdentical } from '../utils';
// import { carrierSuites } from '../utils/Variables/carrierSuites'
import { WriteToDeleteDialog } from "../components/Feedback"
import { AxiosError } from 'axios';
import { toast } from 'sonner';
import { z } from "zod";

const useNumberserieValidation = () => {  
  const { t } = useTranslate();
  // Zod schema with translations
  const NumberSerieSchema = z.object({
    from: z.string(),
    to: z.string(),
    current: z.string(),
  }).refine((data) => parseInt(data.from) < parseInt(data.to), {
    message: t('fromSmallerThanTo'),
    path: ['from'],
  }).refine((data) => parseInt(data.current) >= parseInt(data.from) && parseInt(data.current) <= parseInt(data.to), {
    message: t('currentBetweenFromAndTo'),
    path: ['current'],
  });

  return NumberSerieSchema;
};

export default function Carriers() {
  const api = useAxiosPrivate();
  const logout = useLogout();
  const [ carrierSuites, setCarrierSuites ] = useState<any[]>([])
  const [ carriersList, setCarriersList ] = useState<any[]>([])
  const [ originalCarrier, setOriginalCarrier ] = useState<any>({})
  const [ carrier, setCProp, setCarrier ] = useJSONState({})
  const [ carrierIdToDelete, setCarrierIdToDelete ] = useState("");

  const [ changed, setChanged] = useState<number>(0) //Checks if form has changed
  const { t } = useTranslate();
  const navigate = useNavigate()
  const { carrierId } = useParams();
  const [ dialog, setDialog ] = useState<any>(null);
  const numberserieValidation = useNumberserieValidation();

  const getCarrierSuites = async () => {
    const response = await api.get({endpoint: "/carrierSuites", mount: null})
    console.log(response.data)
    if (response.data)
      setCarrierSuites(response.data)
  }

  const getCarrierList = async () => {
      try{
        setCarriersList([])
        const response = await api.get({endpoint: "/carriers", params: {ts: new Date().getTime()}, mount:null})
        if (response.data.length > 0) {
          setCarriersList(response.data)
        }          
        else {
          setCarriersList([])
        } 
      }catch(e){
        console.error(e)
        logout()
      }    
  }
  
  useEffect(() => {
    getCarrierSuites()
    getCarrierList()
  }, [])

  const getCarrier = async () => {
    if (carrierId === undefined){
      if (carriersList[carriersList.length - 1]?.name !== "") return
      setCarrier({})
      setOriginalCarrier({})
      return
    }
    try{
      const response = await api.get({endpoint: `carriers/${carrierId}`, mount:null})  
      setCarrier(response.data)
      setOriginalCarrier(response.data)
      setChanged(0)
    }catch(e){
      console.error(e)
    }   
  }

  useEffect(() => {  
    getCarrier()
  }, [carrierId])

  useEffect(() => {
    console.log({carrier})
    areIdentical(carrier, originalCarrier) ? setChanged(0) : setChanged(1)
  }, [carrier])

  const SaveCarrier = async (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    let requestBody = deepCopyOf(carrier)

    if (!requestBody.carrierId || requestBody.carrierId == "") requestBody.carrierId = "new"        
    try{

      if(requestBody.consNoFromAPI === false){
        const c = requestBody.numberSeries.consignment
        numberserieValidation.parse({
          from: c.from,
          to: c.to,
          current: c.current ?? c.from,
        })
      }
      if(requestBody.pkgNoFromAPI === false){
        const c = requestBody.numberSeries.package
        numberserieValidation.parse({
          from: c.from,
          to: c.to,
          current: c.current ?? c.from,
        })
      }
      
      await api.put({
        endpoint: `/carriers/${requestBody.carrierId}`,
        data: requestBody,
        mount: {loadingText: t("saving")}
      })
      
      setOriginalCarrier(requestBody)
      setChanged(0)
      getCarrierList()
      if (requestBody.carrierId === "") navigate(`/carriers/${requestBody.carrierId}`, {replace: true})
      // setTimeout(() => setChanged(0), 10)
    }catch(e){
      console.error(e)
    }       
  }

  const deleteCarrier = async () => {
    try{
      await api.delete({
        endpoint: `/carriers/${carrierIdToDelete}`,
        mount: {loadingText: t("deleting")}
      })
      setCarrierIdToDelete("")
      setCarrier({});
      setOriginalCarrier({});
      setChanged(0)
      getCarrierList()

      toast.success("Carrier deleted")
    }catch(e){
      console.error(e)
      
      if (e instanceof AxiosError) {
          const error = e.response?.data?.message || e.response?.data || e.message
          toast.error(error)
      } else {
          toast.error('An error occurred')
      }
    }
  }

  const generateNeededFields = () => {
    if(!carrier.carrierSuiteId) return <></>
    const neededFields = carrierSuites.find((c:any) => c.value === carrier.carrierSuiteId)?.neededFields;

    const generateGroupFields = (group: string) => {      
      return <>{
      neededFields[group].map((nf:any, i:number) => {

        if(nf.field === "transferProtocol"){
          return <Select 
            value={carrier[nf.field]} 
            required={nf.required != "never"} 
            data={[{name: "SFTP", value: "SFTP"}, {name: "FTP", value: "FTP"}]}
            onChange={(v) => setCProp(v.target.value, nf.field)}
            label={nf.name ?? t(nf.field)}
          />
        }

        return <TextField 
          value={carrier[nf.field]} 
          _type={((/secret|password/i).test(nf.field.toLowerCase())) ? "password" : "text"}
          required={nf.required != "never"} 
          key={group+i} 
          onChange={(v) => setCProp(v.target.value, nf.field)}
        >{nf.name ?? t(nf.field)}</TextField>
      })   
      }</>
    }

    return <>{
    Object.keys(neededFields).map((nfGroup: any) => {
      if(nfGroup == "EDI" && !carrier.documents.some((d:any) => d.document == "transport instructions" && d.checked))
        return <></>
      if(nfGroup.length > 2)
        return <Container key={nfGroup}>
          <Accordion label={t(nfGroup)}  defaultOpen={true}>  
            <Grid>{generateGroupFields(nfGroup)}</Grid>
          </Accordion>
        </Container>
      else return <Container key={nfGroup}>
          <Grid>{generateGroupFields(nfGroup)}</Grid>
        </Container>
    })
    }</>
  }  

  return ( 
    <div className='schipt-carriers'>
      {dialog && <ConfirmDialog options={dialog} />}
      {carrierIdToDelete ?
        <WriteToDeleteDialog 
          title={t("dialogs.sureDeleteCarrier")} 
          textToWrite={carrier.name} 
          message={t("dialogs.repeatTextToDelete") + carrier.name}
          onCancel={() => setCarrierIdToDelete("")}
          onOk={() => { deleteCarrier() }}
        />
      : <></>
      }      
      <Centralizer style={{width:"100vw"}}>
        <Container style={{
          height:"40vh", 
          maxHeight:"50vh",
          position:"static",
          width: "clamp(1000px, 80%, 80vw)"
        }}>
          { carrier && Object.keys(carrier).length > 0 ?
            <form onSubmit={SaveCarrier}>
              <Group label={carrier.name}>
                <Grid style={{ marginTop:"20px"}}>
                  <Select 
                    label={t("CarrierSuite")}
                    value={carrier.carrierSuiteId} 
                    data={carrierSuites}
                    onChange={async (e) => { setCProp(e.target.value, "carrierSuiteId"); } }
                    required={true}
                    blankOption={true}
                    style={{ width: "15rem"}}
                  />
                  <TextField 
                    required
                    value={carrier.name} 
                    onChange={(v) => setCProp(v.target.value, "name")}
                  >{t("name")}</TextField>                   
                </Grid>
                {
                  generateNeededFields()
                }
                                
                <Group label={t("documents")}>
                  <Grid>
                    {
                      carrier.documents && carrier.documents.map((d: any, i:number) => {
                        return <Switch 
                          key={i} 
                          label={t(d.document)}
                          checked={d.checked}
                          onChange={(v) => {setCProp(v.target.checked, "documents", i, "checked")}} 
                        />
                      })
                    }
                  </Grid>  
                </Group>

                {originalCarrier?.consNoFromAPI === undefined && originalCarrier?.pkgNoFromAPI === undefined ? <></>:
                  <Accordion label={t("numberseries")}>
                    {originalCarrier?.consNoFromAPI === undefined ? <></>:                                          
                      <Group label={t("consignmentNumber")}>
                        <Switch label={t("ownConsignmentNumberserie")}
                          checked={!carrier.consNoFromAPI}
                          onChange={(v) => setCProp(!v.currentTarget.checked, "consNoFromAPI")}
                        />
                        <Grid>                            
                          { carrier.consNoFromAPI ? <></>:
                          <>
                            <TextField required _type='number' value={carrier.numberSeries?.consignment?.from ?? 0}
                              min={0}
                              onChange={(v) => setCProp(v.target.value, 'numberSeries', 'consignment', 'from')}
                            >{t("from")}</TextField>
                            <TextField required _type='number' value={carrier.numberSeries?.consignment?.to ?? 0}
                              min={0}
                              onChange={(v) => setCProp(v.target.value, 'numberSeries', 'consignment', 'to')}
                            >{t("to")}</TextField>
                            <TextField required _type='number' value={carrier.numberSeries?.consignment?.current ?? carrier.numberSeries?.consignment?.from}
                              onChange={(v) => setCProp(v.target.value, 'numberSeries', 'consignment', 'current')}
                            >{t("current")}</TextField>
                            <TextField readOnly={true} value={carrier.numberSeries?.consignment?.to - (carrier.numberSeries?.consignment?.current ?? 0)}>{t("numbersLeft")}</TextField>
                          </>
                          }   
                        </Grid>
                      </Group>                                         
                    }
                    {originalCarrier?.pkgNoFromAPI === undefined ? <></>:
                      <Group label={t("packageNumber")}>
                      <Switch label={t("ownPackageNumberserie")}
                          checked={!carrier.pkgNoFromAPI}
                          onChange={(v) => setCProp(!v.currentTarget.checked, "pkgNoFromAPI")}
                        />
                      <Grid>
                      { carrier.pkgNoFromAPI ? <></>:
                      <>
                        <TextField required _type='number' value={carrier.numberSeries?.package?.from}
                          onChange={(v) => setCProp(v.target.value, 'numberSeries', 'package', 'from')}
                        >{t("from")}</TextField>
                        <TextField required _type='number' value={carrier.numberSeries?.package?.to}
                          onChange={(v) => setCProp(v.target.value, 'numberSeries', 'package', 'to')}
                        >{t("to")}</TextField>
                        <TextField required _type='number' value={carrier.numberSeries?.package?.current ?? carrier.numberSeries?.package?.from}
                          onChange={(v) => setCProp(v.target.value, 'numberSeries', 'package', 'current')}
                        >{t("current")}</TextField>
                        <TextField readOnly={true} value={carrier.numberSeries?.package?.to - (carrier.numberSeries?.package?.current ?? 0)}>{t("numbersLeft")}</TextField>
                      </>}
                      </Grid>
                    </Group>
                    }
                </Accordion>
                }
                                                      
                {originalCarrier?.carrierId !== "" ?
                  <Button variant='delete' onClick={() => {setCarrierIdToDelete(originalCarrier?.carrierId)}}>{t("deleteCarrier")}</Button>
                  : <></>
                }                
              </Group>
              <ChangeDialog show={changed == 1 ? true : false} 
                undo={async () => {
                  // If "Changed" carrier is a "new" carrier, then remove it.
                  if(originalCarrier?.carrierId === ""){
                    setCarriersList((prevState:any[]) => {
                      const i:number = prevState.findIndex((c:any) => c.name === "")
                      prevState.splice(i)
                      return prevState;
                    })
                    setCarrier({})
                  }
                  else setCarrier(originalCarrier)
                  setTimeout(() => setChanged(0), 10)        
                }}
                
              /> 
            </form>
            :
            <></>            
          }          
          <Accordion label={t("carriers")} defaultOpen={true}>
            <Container style={{maxHeight: "25rem", overflowY:"scroll"}}>
              <DataTable 
                data={carriersList} 
                style={{maxHeight:"34rem"}} 
                hideColumns={["carrierId"]}
                openCallback={async (i:number) => {navigate(`/carriers/${carriersList[i].carrierId}`, {replace: true})}}
              />
            </Container>
            <Button variant="add" onClick={() => {
              setCarrier({ carrierSuiteId: "", carrierId: "" })
              setOriginalCarrier({ carrierSuiteId: "", carrierId: "" })
            }}>{t("newCarrier")}</Button>
          </Accordion>
        </Container>        
      </Centralizer> 
    </div>
  ); 
}