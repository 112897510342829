import { WarehouseType } from "../types"

let whData:WarehouseType | undefined = undefined

const fetch_whData = async (api:any) => {
  try{
    const response = await api.get({endpoint: "/warehouse",mount: null})    
    whData = response?.data as WarehouseType
  }catch(e){
    console.error(e)
  }  
}

export {whData, fetch_whData}