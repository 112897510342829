import { z } from "zod";
import { contactSchema } from "./Contact";

export const addressSchema = z.object({
  type: z.string().max(2).nullish(),
  addressId: z.string().nullish().readonly(),
  name: z.string().max(50),
  street1: z.string().max(35),
  street2: z.string().max(35).optional(),
  countryCode:z.enum(["AT","BE","BG","CY","CZ","DE","DK","EE","ES","EU","FI","FR","GR","HR","HU","IE","IT","IX","LT","LU","LV","MT","NL","PL","PT","RO","SE","SI","SK","XI","AD","AE","AF","AG","AI","AL","AM","AO","AQ","AR","AS","AU","AW","AX","AZ","BA","BB","BD","BF","BH","BI","BJ","BL","BM","BN","BO","BQ","BR","BS","BT","BV","BW","BY","BZ","CA","CC","CD","CF","CG","CH","CI","CK","CL","CM","CN","CO","CR","CU","CV","CW","CX","DJ","DM","DO","DZ","EC","EG","EH","ER","ET","EX","FJ","FK","FM","FO","GA","GB","GD","GE","GF","GG","GH","GI","GL","GM","GN","GP","GQ","GS","GT","GU","GW","GY","HK","HM","HN","HT","ID","IL","IM","IN","IO","IQ","IR","IS","JE","JM","JO","JP","KE","KG","KH","KI","KM","KN","KP","KR","KW","KY","KZ","LA","LB","LC","LI","LK","LR","LS","LY","MA","MC","MD","ME","MF","MG","MH","MK","ML","MM","MN","MO","MP","MQ","MR","MS","MU","MV","MW","MX","MY","MZ","NA","NC","NE","NF","NG","NI","NO","NP","NR","NU","NZ","OM","PA","PE","PF","PG","PH","PK","PM","PN","PR","PS","PW","PY","QA","RE","RS","RU","RW","SA","SB","SC","SD","SG","SH","SJ","SL","SM","SN","SO","SR","ST","SV","SX","SY","SZ","TC","TD","TF","TG","TH","TJ","TK","TL","TM","TN","TO","TR","TT","TV","TW","TZ","UA","UG","UM","US","UY","UZ","VA","VC","VE","WF","VG","VI","VN","WS","VU","XK","XU","YE","YT","YU","ZA","ZM","ZW"]),
  city:z.string().max(50),
  zipCode:z.string().max(15),
  vatNo: z.string().max(20).optional(),
  contacts: z.array(contactSchema).min(0).optional()
});

export type AddressType = z.infer<typeof addressSchema>;