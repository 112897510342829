import { z } from "zod";

export const userSchema = z.object({
    username: z.string().email({ message: 'validation.invalidEmail' }),
    fullName: z.string().max(50, { message: 'validation.invalidMaxLength' }),
    phoneNo: z.string().optional().nullish(),
    createdDt: z.date().nullish(),
    createdBy: z.string().nullish(),
    updatedDt: z.date().nullish(),
    updatedBy: z.string().nullish()
});

export type UserType = z.infer<typeof userSchema>;