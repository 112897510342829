import React, { useEffect, useState } from 'react'
import { useAxiosPrivate, useTranslate, useJSONState } from '../hooks';
import { useNavigate } from 'react-router-dom';
import { WriteToDeleteDialog, Accordion, Button, Centralizer, ChangeDialog, Container, DataTable, Grid, Group, Select, TextField } from '../components';
import { ZodError, set } from 'zod';
import { UserType, userSchema } from '../types/User';
import { areIdentical } from '../utils';
import { Axios, AxiosError } from 'axios';
import { toast } from 'sonner';

const UserAdministration = () => {
  const api = useAxiosPrivate();
  const navigate = useNavigate();
  const { t, tZod } = useTranslate();

  const [changed, setChanged] = useState<boolean>(false) //Checks if form has changed
  const [usersList, setUsersList] = useState<UserType[]>([])
  const [user, setProp, setUser] = useJSONState<UserType | undefined>()
  const [originalUser, setOriginalUser] = useState<UserType | undefined>()
  const [ userToDelete, setUserToDelete ] = useState<string>("")
  const [validationErrors, setValidationErrors] = useState<ZodError<UserType> | null>(null)

  const getUsers = async () => {
    try {
      const response = await api.get({endpoint:'/user', mount:null})
      setUsersList(response.data ?? [])
    } catch (e) {
      console.error(e)
      navigate('/login')
    }
  }

  const onSave = async(e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try{
      setValidationErrors(null)
      const result = userSchema.safeParse(user)
      if (!result.success) {
        setValidationErrors(tZod(result.error))
        return false
      }

      const apiConfig = {
        endpoint: '/user',
        data: { user },
        mount: {
          loadingText: t("saving"),
          onLoadText: t("saved"),
        }
      }

      let response;

      if(!originalUser)  response = await api.post(apiConfig)
      else response = await api.put(apiConfig)

      setUser(response.data)
      setOriginalUser(response.data)
      getUsers()
    }catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    getUsers()
  }, [])

  useEffect(() => {
    console.log({user, originalUser})
    setChanged(!areIdentical(user, originalUser))
  }, [user, originalUser])

  const deleteUser = async () => {
    try{
      await api.delete({endpoint: `/user/${userToDelete}`, mount: {
        loadingText: t("deleting"),
        onLoadText: t("deleted"),
      }})
      setUserToDelete("")
      setUser(undefined)
      setOriginalUser(undefined)
      getUsers()
    }catch(e){
      console.error(e)
    }
  }

  return (
    <Centralizer style={{
      width: "100vw"
    }}>
      <Container style={{
        height: "40vh",
        maxHeight: "50vh",
        position: "static",
        width: "clamp(1000px, 80%, 80vw)"
      }}>
        {user ?
          <>
            <form onSubmit={(e) => onSave(e)}>
              <Group label={" "}>
                <Grid>
                  <TextField
                    required
                    value={user.username || ''}
                    onChange={(v) => setProp(v.target.value, "username")}
                    error={validationErrors?.errors.find(e => e.path[0] === "username")?.message}
                    readOnly={!!originalUser}
                    _type='email'
                  >{t("email")}
                  </TextField>
                  <TextField
                    value={user.fullName || ''}
                    onChange={(v) => setProp(v.target.value, "fullName")}
                    error={validationErrors?.errors.find(e => e.path[0] === "fullName")?.message}
                  >{t("fullName")}
                  </TextField>
                  <TextField
                    value={user.phoneNo || ''}
                    onChange={(v) => setProp(v.target.value, "phoneNo")}
                    error={validationErrors?.errors.find(e => e.path[0] === "phoneNo")?.message}
                    _type='tel'
                  >{t("phone")}
                  </TextField>
                </Grid>
                {user?.username && originalUser ?
                  <Button _type="button" className='schipt-btn primary' variant='delete' onClick={() => setUserToDelete(user.username as string) }>Delete user</Button>
                  : <></>
                }
              </Group>
              <ChangeDialog show={changed}
                undo={async () => {
                  // If "Changed" carrier is a "new" carrier, then remove it.
                  if (originalUser?.username === "") {
                    setUsersList((prevState: UserType[]) => {
                      const i: number = prevState.findIndex((c: UserType) => c.username === "")
                      prevState.splice(i)
                      return prevState;
                    })
                    setUser({} as UserType)
                  }
                  else setUser(originalUser)
                  setTimeout(() => setChanged(false), 10)
                }}
              />
            </form>
          </>
          :
          <></>
        }


        <Accordion label={t("users")} defaultOpen={true}>
          <Container style={{ maxHeight: "25rem", overflowY: "scroll" }}>
            <DataTable
              data={usersList as UserType[]}
              style={{ maxHeight: "24rem" }}
              openCallback={async (i: number) => { 
                setOriginalUser(usersList[i])
                setUser(usersList[i])
               }}
            />
          </Container>
          <Button variant="add" className='schipt-btn primary' onClick={() => { 
            setOriginalUser(undefined)
            setUser({} as UserType)
           }}>New User</Button>
        </Accordion>
      </Container>
      {userToDelete == "" ? <></> :
        <WriteToDeleteDialog 
          title={t("dialogs.sureDeleteUser")} 
          textToWrite={userToDelete} 
          message={t("dialogs.repeatTextToDelete") + userToDelete}
          onCancel={() => setUserToDelete("")}
          onOk={() => { deleteUser() }}
        />
      }      
    </Centralizer>
  )
}

export default UserAdministration