import React, { useState, FC, useEffect } from "react"
import "../../assets/style/Table.css"
import { useTranslate } from "../../hooks"
import { format } from "date-fns"
import { FaCheck, FaCircle, FaMagnifyingGlass, FaTrash } from 'react-icons/fa6';
import { Button } from "../Inputs";
import { IconType } from "react-icons";

type DataType = {
  [key:string]: string | number | boolean | Date | JSX.Element | null | undefined | any
}

type FetchDataType = {
  page: number, 
  snapshot:string, 
  pageSize:number,
  [key:string]: any
}

type Props<T extends DataType> = {
  data?: T[],
  fetchData?: ((params: FetchDataType) => Promise<void | {data:T[];snapshot:string;pages:number;}>)
  pageSize?: number
  rerender?: boolean;
  hideHeaders?: boolean, 
  hideColumns?: Array<keyof T>;
  sortData?: React.Dispatch<React.SetStateAction<any>>,
  openCallback?: (i:number, row: T) => void,
  deleteCallback?: (i:number, row: T) => void,
  translatePath?: string,
  style?: any,
  [key:string]: any
}

/**
 * 
 * @param data the data rendered. 
 * @param hideHeaders Hides the header
 * @param sortData a function to sort the data
 * @param openCallback a function being called when a row is clicked.
 * @param deleteCallback a function being called when the "trashcan" is clicked. Will not be shown if left undefined.
 * @param hideColumns an array of columns not to show in the table.
 * @returns 
 */

const DataTable = <T extends DataType>({
  data,
  hideHeaders,
  sortData,
  openCallback,
  deleteCallback,
  translatePath,
  fetchData,
  rerender,
  pageSize,
  style,
  hideColumns,
  ...props
}: Props<T>) =>  {
    const [ lastSort, setLastSort] = useState<string>()
    const { t } = useTranslate()
    const [ page, setPage ] = useState(1)
    const [ pages, setPages ] = useState(0)
    const [ useData, setUseData ] = useState<T[]>(data ?? [])
    const [ snapshot, setSnapshot ] = useState<string>("")

    const [ isLoading, setIsLoading ] = useState(false)

    const openable = (typeof openCallback !== 'undefined') 
    const deleteable = (typeof deleteCallback !== 'undefined')

    useEffect(() => {
      if(!fetchData) return
      setPage(1)
      let isMounted = true
      setTimeout(() => isMounted && setIsLoading(true), 300)

      fetchData({page:1, snapshot:"", pageSize: pageSize ?? 50})
        .then(response => {
          
          if(!response?.data) return
          setSnapshot(response.snapshot ?? "");
          setPages(response.pages ?? 1);
          setUseData(response.data)
        })
        .finally(() => {isMounted = false; setIsLoading(false);})
    }, [rerender])

    useEffect(() => {
      if(!fetchData) return
      let isMounted = true
      setTimeout(() => isMounted && setIsLoading(true), 300)

      fetchData({page, snapshot, pageSize: pageSize ?? 50})
        .then(response => setUseData(response?.data ?? []))
        .finally(() => {isMounted = false; setIsLoading(false);})
    }, [page])

    const SkeletonTable = (rows:number, columns:number) => {
      return (
        <div className="skeleton-table">
          <div className="skeleton-header">
            {Array.from({ length: 7 }).map((_, colIndex) => (
              <div key={colIndex} className="skeleton-cell skeleton-header-cell"></div>
            ))}
          </div>
          <div className="skeleton-body">
            {Array.from({ length: rows }).map((_, rowIndex) => (
              <div key={rowIndex} className="skeleton-row">
                {Array.from({ length: 1 }).map((_, colIndex) => (<div key={colIndex} className="skeleton-cell"></div>))}
              </div>
            ))}
          </div>
        </div>
      );
    };

    if(isLoading) return SkeletonTable(pageSize ?? 50, 1)
  
    if (Array.isArray(useData) === false)  return <p>Ingen data</p>
    if (useData.length < 1) return <p>Inga rader</p>
    if (useData.some((row) => typeof row !== "object")) return <p>felaktig array</p>    

    let columns = Object.keys(useData[0])
    if (deleteable) columns = ['deleteable', ...columns]
    if (openable) columns = ['openable', ...columns]

    if (hideColumns)
      columns = columns.filter(c => !hideColumns.includes(c))

    const sortBy = (key:string) => {
      if (sortData === undefined) return

      let obj = [...useData] // Deepcopy not allowed

      obj.sort((a, b) => {
        let val1 = key === lastSort ? b[key] : a[key]
        let val2 = key === lastSort ? a[key] : b[key]
        if ((val1 || '') < (val2 || '')) {
          return -1;
        }
        if ((val1 || '') > (val2 || '')) {
          return 1;
        }
        return 0
      })
      setLastSort(key === lastSort ? undefined : key)
      
      sortData(obj);
    }

    const getCellValue = (key:string, value:string | JSX.Element | undefined) => {

      if(typeof value === "object") return value
      if(typeof value === "undefined") return <></>

      if((/^http.*\.jpg$/i).test(value)) return <img src={value} alt={t(key)} />

      if((/(\n)/i).test(value)) return <>{value.split("\n").map(v => {
        return <div>{v}</div>
      })}</>

      return value
    }

    const getPageStepper = () => {
      const maxSteps = 7; // Always show 7 pages
      const halfSteps = Math.floor(maxSteps / 2);

      let startPage = Math.max(1, page - halfSteps); // Start page range
      let endPage = Math.min(pages, page + halfSteps); // End page range

      // Adjust range if there aren't enough pages at the start or end
      if (endPage - startPage + 1 < maxSteps) {
        if (startPage === 1) {
          endPage = Math.min(pages, startPage + maxSteps - 1);
        } else if (endPage === pages) {
          startPage = Math.max(1, endPage - maxSteps + 1);
        }
      }

      // Generate page steps
      const pageSteps: number[] = [];
      for (let i = startPage; i <= endPage; i++) {
        pageSteps.push(i);
      }
      
      

      return <div className="pages">
        {page > 1 && <div onClick={() => setPage(p => p-1)}>{t("previous")}</div>}
        {pageSteps.map(p => <div onClick={() => setPage(p)} className={p === page ? "active" : ""}>{p}</div>)}
        {page < pages && <div onClick={() => setPage(p => p+1)}>{t("next")}</div>}
      </div>;
    }
    
    return (<div className="schipt-table-body" {...props}>
        <table style={style}>
        {!hideHeaders && 
          <thead>
            <tr key={"r_-1"}>{useData[0] &&
              columns.map((key, i) => {
                if (["openable", "deleteable"].includes(key)) return <th key={`r_-1_${i}`}/>
                return <th className="text-nowrap" onClick={() => sortBy(key)} key={`r_-1_${i}`}>{translatePath ? t(`${translatePath}.${key}`) : t(key)}</th>
              })
            }</tr>
          </thead>
        }
        <tbody>{          
          useData.map((row, i) => {
            return <tr key={i} onDoubleClick={() => openCallback?.(i, row)}>{
              row &&
              columns.map((key, j) => {
                const type = typeof row[key]
                let content: JSX.Element | string | undefined;
                let clickEvent

                 // Check if date
                if (type === "boolean") content = row[key] ? <FaCheck className='text-schipt-black dark:text-schipt-white' />: "";
                else if (row[key] instanceof Date) content = format(new Date((row[key] as unknown) as string), "yyyy-MM-dd HH:mm")
                else if (type === "object") content = row[key] as JSX.Element
                else content = row[key]?.toString()

                if (key === "openable"){
                  clickEvent = () => openCallback?.(i, row)
                  content = <FaMagnifyingGlass className='text-schipt-black dark:text-schipt-white' />
                } 
                if (key === "deleteable"){                  
                  clickEvent = () => deleteCallback?.(i, row)
                  content = <FaTrash className='text-schipt-black dark:text-schipt-white' />
                }              

                return <td 
                  title={typeof clickEvent !== 'undefined' || type == "object" ? undefined: content as string}
                  onClick={clickEvent}
                  className = {(typeof clickEvent !== 'undefined' ? "clickable": undefined) + ' text-nowrap'}
                  key={`r_${i}_${j}`}
                >
                  {getCellValue(key, content)}
                </td>
              })
            }</tr>
          })
        }</tbody>
      </table>
      {getPageStepper()}
    </div>)
    
}

export default DataTable