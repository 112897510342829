import React, { FC, useState, useRef, useEffect } from 'react'
import { ConfirmDialog, Centralizer, Container, Group, ChangeDialog, DataTable
  , Button, Grid, TextField, Select, WriteToDeleteDialog
} from '../components';
import { useTranslate, useLogout, useAxiosPrivate, useJSONState } from '../hooks';
import { useNavigate } from 'react-router-dom';
import { AddressType} from '../types';
import { countryList, areIdentical } from '../utils';
import { toast } from 'sonner';


const AddressRegister = () => {
  const api = useAxiosPrivate();
  const navigate = useNavigate();
  const logout = useLogout();
  const [ dialog, setDialog ] = useState<any>(null);
  const [ addresses, setAddresses] = useState<AddressType[]>([])
  const [ originalAddressForm, setOriginalAddressForm ] = useState<AddressType>()
  const [ addressForm, setAddressFormProp, setAddressForm ] = useJSONState<AddressType | undefined>();
  const [ changed, setChanged ] = useState(false)
  const { t } = useTranslate();
  const [ addressToDelete, setAddressToDelete ] = useState("");

  const onSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try{
      const response = await api.put({
        endpoint:`/addressRegister`, 
        data:addressForm,
        mount: {
          loadingText: t("saving")
        }
      })      
      setAddresses(response.data ?? [])
      setOriginalAddressForm(addressForm)
    }catch (e) {
      console.error(e)
    }
  }

  const getAddresses = async () => {
    try {
      setAddresses([])
      const response = await api.get({endpoint: "/addressRegister", mount: null})
      let foo = response.data
      console.log({foo})
      setAddresses(response.data ?? [])
    } catch (e) {
      console.error(e)
      toast.error(t(e))
    }
  }

  const deleteAddress = async () => {
    try{
      const response = await api.delete({
        endpoint: `/addressRegister/${addressToDelete}`,
        mount: {
          loadingText: t("deleting")
        }
      })      
      setAddresses(response.data ?? [])
      setAddressForm(undefined)
      setAddressToDelete("")
      toast.success(t("addressDeleted"))
      setChanged(false)
    }catch (e) {
      console.error(e)
      toast.error(t(e))
    }
  }

  useEffect(() => {
    getAddresses()
  }, [])

  useEffect(() => {
    setChanged(!areIdentical(addressForm, originalAddressForm))
  }, [addressForm, originalAddressForm])

  const addressFormElement = () => {
    if(!addressForm) return <></>
    return <Group>
      <Centralizer>
        <Container>
          <Group label={t("address")}>
            <Grid >
            <TextField
                required
                value={addressForm.addressId}
                onChange={(v) => setAddressFormProp(v.target.value, "addressId")}
              >{t("addressId")}</TextField>
              <TextField
                required
                value={addressForm.name}
                onChange={(v) => setAddressFormProp(v.target.value, "name")}
              >{t("name")}</TextField>
              <TextField
                required
                value={addressForm.street1}
                onChange={(v) => setAddressFormProp(v.target.value, "street1")}
              >{t("street1")}</TextField>
              <TextField
                value={addressForm.street2}
                onChange={(v) => setAddressFormProp(v.target.value, "street2")}
              >{t("street2")}</TextField>
              <Select
                //required={isRequired('countryCode',addressSchema)}
                label={t("country")}
                data={countryList}
                value={addressForm.countryCode ?? "SE"}
                onChange={(e) => { setAddressFormProp(e.target.value, 'countryCode') }}
              // readOnly={locked}
              ></Select>
              <TextField
                required
                value={addressForm.city}
                onChange={(v) => setAddressFormProp(v.target.value, "city")}
              >{t("city")}</TextField>
              <TextField
                required
                value={addressForm.zipCode}
                onChange={(v) => setAddressFormProp(v.target.value, "zipCode")}
              >{t("zipCode")}</TextField>
            </Grid>
          </Group>
          <Group label={t("contact")}>
            <Grid>
              <TextField
                value={addressForm.contacts?.[0].name}
                onChange={(v) => setAddressFormProp(v.target.value, "contacts", 0, "name")}
              >{t("name")}</TextField>
              <TextField
                value={addressForm.contacts?.[0].email}
                onChange={(v) => setAddressFormProp(v.target.value, "contacts", 0, "email")}
                _type='email'
              >{t("email")}</TextField>
              <TextField
                value={addressForm.contacts?.[0].phoneNo}
                onChange={(v) => setAddressFormProp(v.target.value, "contacts", 0, "phoneNo")}
                _type='tel'
              >{t("phone")}</TextField>
            </Grid>
          </Group>
          <Group label={t("information")}>
            <Grid>
              <TextField
                required
                value={addressForm.vatNo}
                onChange={(v) => setAddressFormProp(v.target.value, "vatNo")}
              >{t("vatNo")}</TextField>
            </Grid>
          </Group>
        </Container>        
      </Centralizer>
      {
        originalAddressForm?.addressId ?
        <Button
          onClick={() => setAddressToDelete(addressForm.addressId as string)}
          variant='delete'
          _type='button'
        >{t("delete")}</Button>
        : <></>
      }      
    </Group>
  }

  return (<div className='schipt-addressregister'>
    <form onSubmit={(e) => onSave(e)}>
      <Centralizer>
        <Container>
          {addressFormElement()}
          {addresses.length > 0 ?
            <DataTable 
              data={addresses.reduce((prev:any, cur) => {
                let r = {...cur} as ({contacts:any, countryCode:any})
                delete r.contacts
                delete r.countryCode
                prev.push(r)
                return prev
              }, [])}
              openCallback={(i) => {
                setOriginalAddressForm(addresses[i])
                setAddressForm(addresses[i])
              }}
            />
          : <></>
          
          }          
          <Button variant="add" onClick={() => {
            setOriginalAddressForm(undefined)
            setAddressForm({} as AddressType)
          }}>{t("newAddress")}</Button>
        </Container>
      </Centralizer>
      
      <ConfirmDialog options={dialog} />
      <ChangeDialog show={changed}
          undo={async () => {
            console.log("Undoing..")
            setAddressForm(originalAddressForm as AddressType)
          }}
        />
    </form>
    {addressToDelete ? 
      <WriteToDeleteDialog 
        title={t("dialogs.sureDeleteAddress")}
        message={t("dialogs.repeatTextToDelete") + addressToDelete} 
        textToWrite={addressToDelete}
        onCancel={() => setAddressToDelete("")}
        onOk={() => deleteAddress()}
      />
      : <></>
    }
  </div>)
}

export default AddressRegister