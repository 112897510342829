import React, {FC, useState, useEffect, useId} from 'react'
import "../../assets/style/Select.css"
import { useTranslate } from '../../hooks'

interface CommonSelectProps {
  label?: string;
  _ref?: React.MutableRefObject<null>;
  onChange?: (t: React.ChangeEvent<HTMLSelectElement>) => void;
  required?: boolean;
  value?: string | number;
  blankOption?: boolean;
  style?: any;
  readOnly?: boolean;
  error?: string;
  isDatalist?: boolean;
  [key: string]: any; // Allow other custom props
}

type SelectProps<T extends { [key: string]: any }[] = { [key: string]: any }[]> = CommonSelectProps &
  (T extends { name: string; value: string | number }[] 
    ? {
        data: T; // Data with `name` and `value` properties
        nameKey?: keyof T[0]; // `nameKey` is optional if `name` exists
        valueKey?: keyof T[0]; // `valueKey` is optional if `value` exists
      }
    : T extends { name: string }[] 
      ? {
          data: T; // Data with `name` but without `value`
          nameKey?: keyof T[0]; // `nameKey` is optional, `name` exists
          valueKey: keyof T[0]; // `valueKey` is required because `value` doesn't exist
        }
      : T extends { value: string | number }[] 
        ? {
            data: T; // Data with `value` but without `name`
            nameKey: keyof T[0]; // `nameKey` is required because `name` doesn't exist
            valueKey?: keyof T[0]; // `valueKey` is optional, `value` exists
          }
        : {
            data: T; // Data without `name` or `value`
            nameKey: keyof T[0]; // `nameKey` is required because `name` doesn't exist
            valueKey: keyof T[0]; // `valueKey` is required because `value` doesn't exist
          });


/**
 * @param label - The label for the select element
 * @param _ref A ref object to reference the select element
 * @param {Array<{name:string, value:string}>} data - An array of objects representing the options for the select element.
 * @param {function} onChange - A callback function that will be ran when a user changes the value.
 * @param {string | number} value - The value of the Select.
 * @param {boolean} required - Whether the select is required/mandatory in a form or not.
 * @param {boolean} blankOption - Adds a blank disabled option in the beginning.
 * @param {boolean} isDatalist - Converts the select to a datalist instead.
 * @returns 
 */
function Select<T extends { [key: string]: any; }[]>({
  data,
  nameKey, // Default to "name"
  valueKey, // Default to "value"
  label, _ref, onChange, value, required, blankOption, style, readOnly, error, isDatalist, ...props
}: SelectProps<T>): JSX.Element {

  const { t } = useTranslate();
  const id = useId()

  if(isDatalist){
    return (<div className='relative schipt-i' style={style}>
      <input className="w-full text-sm py-1 pl-1 border-schipt-dark-gray border-solid border rounded-sm bg-white dark:bg-schipt-dark font-montserrat focus:outline-none placeholder-transparent  focus:shadow-3xl focus:shadow-schipt-dark-gray transition-colors peer"
        onChange={(v) => onChange?.((v as unknown) as React.ChangeEvent<HTMLSelectElement>)} value={value} required={required} disabled={readOnly} list={id}
        {...props}
      />      
      <datalist id={id}>
      {
        data?.length > 0 && data.map((d, i) => {
          const name = nameKey || (d.name ? "name" : undefined);
          const value = valueKey || (d.value ? "value" : undefined);

          if(name === undefined || value === undefined) return <></>

          return <option value={d[value]} key={i}>{d[name]}</option>
        })
      }
      </datalist>
      <label className="absolute left-0 -top-5  text-schipt-dark-gray text-xs font-semibold font-montserrat peer-focus:-top-5 peer-focus:font-md peer-focus:text-md peer-focus:left-0 peer-focus:pl-0 transition-all peer-placeholder-shown:text-schipt-dark-gray peer-placeholder-shown:text-opacity-50 peer-placeholder-shown:pl-1 peer-placeholder-shown:top-2">{label}</label> 
      {error ? <><br /><p className='absolute -bottom-3.5 text-xs text-rose-500 w-100'>{error}</p></> : <></>}
    </div>)
  }

  return (<div className='relative w-full md:w-auto schipt-i' style={style}>
    <select className="w-full text-sm py-1 pl-1 border-schipt-dark-gray border-solid border rounded-sm bg-white dark:bg-schipt-dark font-montserrat focus:outline-none placeholder-transparent  focus:shadow-3xl focus:shadow-schipt-dark-gray transition-colors peer"
      onChange={(v) => onChange?.(v)} value={value} required={required} disabled={readOnly}
      {...props}
    >
    <option disabled={!blankOption} value="" key={-1}>{t("chooseAnOption")}</option>
    {
      data?.length > 0 && data.map((d, i) => {
        const name = nameKey || (d.name ? "name" : undefined);
        const value = valueKey || (d.value ? "value" : undefined);

        if(name === undefined || value === undefined) return <></>

        return <option value={d[value]} key={i}>{d[name]}</option>
      })
    }
    </select>
    <label className="absolute left-0 -top-5  text-schipt-dark-gray dark:text-schipt-white-90 text-xs font-semibold font-montserrat peer-focus:-top-5 peer-focus:font-md peer-focus:text-md peer-focus:left-0 peer-focus:pl-0 transition-all peer-placeholder-shown:text-schipt-dark-gray peer-placeholder-shown:text-opacity-50 peer-placeholder-shown:pl-1 peer-placeholder-shown:top-2">{label}</label> 
    {error ? <><br /><p className='absolute -bottom-3.5 text-xs text-rose-500 w-100'>{error}</p></> : <></>}
  </div>)    
}

export default Select